.page-wrap {

}

.inner-section-container {
	padding: 30px 0;
	@include breakpoint($min992) {
		padding: 60px 0;
	}
	@include breakpoint($min1200) {
		padding: 100px 0;
	}
}

/*
	Home Position Sec
----------------------------------------------------------------------------------------------------------------------*/

.position-section {
	box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, .4);
	.inner-section-container {
		background-image: url("../img/mapa-srbije-1140-light.png");
		background-position: 0 50px;
		background-repeat: no-repeat;
		background-size: contain;
		@include breakpoint($min992) {
			background-position: 0 -30px;
			background-size: cover;
		}
	}
}

.position-item {
	position: relative;
	margin-bottom: 10px;

	@include breakpoint($min992) {
		margin-bottom: 20px;
	}
	@include breakpoint($min1200) {
		margin-bottom: 30px;
	}
}

.position-item__icon {
	color: $orange_c;
	position: absolute;
	font-size: 24px;
	line-height: 24px;
	@include breakpoint($min768) {
		font-size: 60px;
		line-height: 65px;
	}
	@include breakpoint($min992) {
		font-size: 70px;
		line-height: 65px;
	}
	@include breakpoint($min1200) {
		font-size: 80px;
		line-height: 65px;
	}
}

.position-item__text {

	margin-left: 40px;
	color: $text_c;
	font-size: 24px;
	line-height: 28px;
	font-weight: 800;
	@include breakpoint($min768) {
		margin-left: 70px;
		font-size: 60px;
		line-height: 65px;
	}
	@include breakpoint($min992) {
		margin-left: 70px;
		font-size: 70px;
		line-height: 65px;
	}
	@include breakpoint($min1200) {
		margin-left: 80px;
		font-size: 80px;
		line-height: 65px;
	}

}

.position-item-half-wrap {
	@extend .clearfix;
}

.position-item-half {
	float: left;
	min-width: 470px;
}

/* paral-sec
-------------------------------------------------------*/
.paral-sec {
	padding: 100px 0;
	background-color: #fff;
	text-align: center;
	@include breakpoint($min992) {
		padding: 200px 0;
	}
}

.paral-sec__title {
	font-size: 32px;
	font-weight: 800;
	color: #fff;
	margin: 0 0 25px;
	@include breakpoint($min992) {
		font-size: 70px;
	}
}

.paral-sec__text {
	color: #fff;
	font-size: 18px;
	margin: 15px 0;

	@include breakpoint($min992) {
		font-size: 24px;
		margin: 30px 0;
	}
}

.paral-sec.bg-light {
	background-color: #f7f7f7;
}

/* news-section
-------------------------------------------------------*/
.news-section {
	box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, .4);
	.inner-section-container {
		background-image: url("../img/mapa-apatin-1140-light.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 -70px;
	}
}

/*   Page with sidebar
----------------------------------------------------------------------------------------------------------------------*/
.page-title-wrap {
	padding: 20px 0;
}

.page-wysiwyg-content {
	padding: 20px 0;
}

.page-main-img {
	margin-bottom: 30px;
}

.wysiwyg-content__kontakt {
	margin-bottom: 20px;
	@include breakpoint($min992) {
		float: right;
		width: 50%;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	padding: 20px;
	border: 1px solid #e5e5e5;
	background-color: #f7f7f7;
}

.sub-static__galery {
	.sub-static__galery-header {
		margin: 20px 0;
	}
	.sub-static__galery-title {
		margin: 0;
	}
	.work-item {
		margin-bottom: 30px;
	}
}

/*   Search page
----------------------------------------------------------------------------------------------------------------------*/
.search-prev {

	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e5e5e5;
	&:last-child {
		border-bottom: none;
	}
}

.search-prev__header {
	position: relative;
	a {
		color: $text_c;
		&:hover, &:focus {
			text-decoration: none;
			color: $orange_c;
		}
	}
}

.search-prev__num {
	position: absolute;
	top: 0;
	left: 0;
	border: 4px solid #222b34;
	width: 32px;
	height: 32px;
	text-align: center;
	line-height: 26px;
	font-size: 20px;
	font-weight: 800;
	a {

	}

}

.search-prev__title {
	margin-left: 50px;
}

/*   page-gallery
----------------------------------------------------------------------------------------------------------------------*/
.page-gallery__body {
	.work-item {
		margin-bottom: 30px;
	}
}

/*   box_list
----------------------------------------------------------------------------------------------------------------------*/
.box_list {
	padding: 60px 0;
	.work-item {
		margin-bottom: 30px;
	}
}

/*   grad_prev
----------------------------------------------------------------------------------------------------------------------*/
.grad_prev {
	min-height: 250px;
	position: relative;
	.grad_prev-grb {
		position: absolute;
		width: 200px;
		height: 200px;
		left: 0;
		top: 0;
		img {
			max-width: 100%;
		}
	}
	.grad_prev-body {
		margin-left: 220px;
		margin-bottom: 30px;
		padding-bottom: 15px;
		border-bottom: 1px solid #e5e5e5;
	}
}

/*   404 page
----------------------------------------------------------------------------------------------------------------------*/
.page-404 {
	text-align: center;
}

.page-404 .error-404 {
	font-size: 200px;
	font-weight: 800;
}

.page-404 a,
.page-404 p {
	font-size: 14px;
}

.search-button {
	position: absolute;
	top: 12px;
	right: 15px;
	background-color: transparent;
	border: none;
}

.search-button i {
	color: #a1a1a1;
}

/*   prezentacija-slider
----------------------------------------------------------------------------------------------------------------------*/
.simple-ajax-popup {
	//border: 1px solid $blue_c;
	display: block;
}

.prezentacija-slider-wrap {
	max-width: 960px;
	margin: 0 auto;
}

.ajax-popup__mfp-close {
	width: 84px;
	height: 84px;
	line-height: 84px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: .85;
	padding: 0 0 18px 10px;
	color: #fff;
	font-style: normal;
	font-size: 68px;
	font-family: Arial, Baskerville, monospace;
}

/*   page-slider-wrap
----------------------------------------------------------------------------------------------------------------------*/
.page-slider-wrap {
	.work-item {
		border: 1px solid #e5e5e5;
	}
}