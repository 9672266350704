/*  news-prev
----------------------------------------------------------------------------------------------------------------------*/
.home-news-list {
	background-color: rgba(#fff, .5);
	padding: 20px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
	0 1px 5px 0 rgba(0, 0, 0, 0.12),
	0 3px 1px -2px rgba(0, 0, 0, 0.2);
	@include breakpoint($min480) {
		padding: 40px;
	}
}

.news-prev {
	position: relative;
	min-height: 54px;
	margin-bottom: 15px;
}

.news-prev__date {
	position: absolute;
	left: 0;
	top: 0;
	border: 4px solid $text_c;
	width: 48px;
	height: 48px;
	text-align: center;
}

.news-prev__date-day {
	font-weight: 700;
	font-size: 22px;
	line-height: 25px;
	display: block;
}

.news-prev__date-month {
	font-weight: 700;
	font-size: 11px;
	line-height: 14px;
	text-transform: uppercase;
	display: block;
	opacity: .8;
	//color: lighten($text_c, 30%);
}

.news-prev__text {
	margin-left: 60px;
}

/*  news-prev-large
----------------------------------------------------------------------------------------------------------------------*/
.news-prev-large {
	position: relative;
	min-height: 170px;
	.news-prev__text {
		@include breakpoint($min480){
			margin-left: 160px;
		}


	}
	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e5e5e5;
	&:last-child {
		border-bottom: none;
	}
}

.news-prev-large__header {
	position: relative;
	@include breakpoint($min480){
		margin-left: 160px;
	}

	margin-bottom: 15px;
	a {
		transition: all .3s ease-in-out;
		display: block;
		@extend .clearfix;
		color: $text_c;
		&:hover, &:focus {
			//background-color: rgba($orange_c, .9);
			color: $orange_c;
		}
	}
}

.news-prev__title {
	min-height: 48px;
	margin-left: 60px;
	margin-right: 20px;
	line-height: 1.2;
	font-size: 16px;
	font-size: 1.6rem;
	text-transform: none;
	letter-spacing: 0;
	margin-bottom: 0;

	@include breakpoint($min992){
		line-height: 1.2;
		font-size: 18px;
		font-size: 1.8rem;
	}
}

.news-prev__img {

	img {
		display: block;
	}
	display: none;
	@include breakpoint($min480) {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 150px;
	}
}

/*  news-full
----------------------------------------------------------------------------------------------------------------------*/
.news-full__img {
	position: relative;

}

.news-full__text {
	.news-prev__date {
		margin-right: 30px;
		margin-bottom: 10px;
		float: left;
		position: static;
	}
}