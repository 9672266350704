.album-prev__link {

}

.album-prev {

}

.img-single-prev {

}

.sidebar-widget-gallery {
	.sidebar-widget__body {
		margin-left: 15px;
	}
}

.sidebar__works-grid {
	margin-left: -5px;
	margin-right: -5px;
	@extend .clearfix;
}

.sidebar__work-item {
	float: left;
	width: 50%;
	padding: 5px;
}

/* Portfolio
-------------------------------------------------------*/

.work-img {
	position: relative;
	overflow: hidden;
}

.work-img img {
	overflow: hidden;
	width: 100%;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.work-img a.btn {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: auto;
	width: auto;
}

.work-img > a,
.work-overlay {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
}

.work-overlay {
	background-color: rgba(20, 20, 20, 0.7);
	border: 10px solid rgba(255, 255, 255, 0.2);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.work-description {
	position: absolute;
	display: block;
	left: 8%;
	top: 8%;
	width: 80%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.work-description h3 {
	font-size: 16px;
	margin-bottom: 5px;
	color: #fff;
}

.work-description a {
	color: #fff;
}

.work-description span {
	font-size: 13px;
	color: #fff;
	display: inline-block;
}

.work-description h3,
.work-description span {
	-webkit-transform: translateX(-360px);
	-moz-transform: translateX(-360px);
	-ms-transform: translateX(-360px);
	-o-transform: translateX(-360px);
	transform: translateX(-360px);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.work-item:hover img {
	-webkit-transform: scale3d(1.1, 1.1, 1);
	-moz-transform: scale3d(1.1, 1.1, 1);
	-ms-transform: scale3d(1.1, 1.1, 1);
	-o-transform: scale3d(1.1, 1.1, 1);
	transform: scale3d(1.1, 1.1, 1);
}

.work-item:hover .work-description h3,
.work-item:hover .work-description span {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}

.work-item:hover .work-description span {
	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.work-item:hover .work-overlay {
	opacity: 1;
	z-index: 1;
}

/* Latest Works
-------------------------------------------------------*/

.work-container {
	//margin-bottom: 40px;
}

.work-item .work-overlay:hover,
.work-item:hover .portfolio-overlay {
	opacity: 1;
	z-index: 1;
}

.portfolio-overlay {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.project-icons {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	margin-top: -20px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.project-icons a {
	display: inline-block;
	width: 40px;
	height: 40px;
	font-size: 14px;
	margin: 0 3px;
	line-height: 40px;
	text-align: center;
	color: $text_c;
	background-color: #fff;
	border-radius: 100px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.project-icons a:hover {
	color: #fff;
	background-color: $orange_c;
}

.work-item:hover .portfolio-overlay .project-icons {
	position: absolute;
	top: 50%;
}

.works-grid.titles .work-description h3,
.works-grid.titles .work-description span {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}

.works-grid.titles .work-description {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	margin-top: 5px;
	text-align: center;
	height: 44px;
	overflow: hidden;
}

.works-grid.titles .work-description a {
	color: #111;
}

.works-grid.titles .work-description a:hover {
	color: $orange_c;
}

.album-list-page {
	.work-item {
		margin-bottom: 20px;
	}
}

.album-single-page {
	.work-item {
		margin-bottom: 30px;
	}
}

