.btn-apa-2color {
	color: #fff;
	font-weight: 700;
	display: block;
	line-height: 0;
	font-size: 0;

	.btn-apa__1 {
		background-color: $text_c;
		padding: 5px 10px;
		line-height: 36px;
		font-size: 18px;
		transition: background-color .2s ease-in-out;
	}
	.btn-apa__2 {
		background-color: $orange_c;
		padding: 5px 10px;
		line-height: 36px;
		font-size: 18px;

	}
	&:hover, &:focus {
		color: #fff;
		.btn-apa__1 {
			background-color: $orange_c;
		}
	}
}

.rev-btn-wrap {
	.btn-apa-2color {

	}
}

.btn.btn-color {
	background-color: $orange_c;
	color: #fff;
	&:hover, &:focus {
		background-color: $text_c;
	}
}