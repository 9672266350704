.footer-inner {
	background-image: url("../img/footer-bg-1.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	p {
		color: #fff;
	}
	a{
		color: #fff;
		&:hover, &:focus {
			color: $orange_c;
		}
	}
}

.footer-widgets {
	margin: 30px 0 20px 0;
}

.footer-get-in-touch {
	p {
		font-size: 18px;
		font-weight: 700;
	}
}

.footer-links {
	li {
		a {
			color: #fff;
			display: block;
			border-bottom: 1px solid rgba(#fff, .2);
			margin-bottom: 10px;
			padding-bottom: 5px;

		}
	}
}

/*  bottom-footer
----------------------------------------------------------------------------------------------------------------------*/

.bottom-footer {
	background-color: $text_c;
	border-top: 1px solid $blue_c;
	//line-height: 110px;
	color: #fff;
	text-align: center;
	a {
		color: #fff;
		&:hover, &:focus {
			color: $orange_c;
		}
	}

}

.footer-signature,
.footer-copyright {
	font-size: 14px;
	line-height: 20px;
	@include breakpoint($min992) {
		line-height: 90px;
		opacity: .8;
	}

}

.footer-signature {

	.apaone {
		background-image: url(../img/apaone-logo.png);
		background-repeat: no-repeat;
		padding-left: 20px;
		background-position: left center;

		text-decoration: none;
	}
}

.footer-socials {
	a {
		display: inline-block;
		padding: 0 15px;
		font-size: 20px;
		line-height: 40px;
		@include breakpoint($min992) {
			font-size: 60px;
			line-height: 90px;
		}

	}
}

.bottom-footer .row {

	@include breakpoint($max992) {
		display: flex;
		align-items: center;

	}
	@include breakpoint($max768) {
		display: block;
	}
}

.bottom-footer__col {
	@include breakpoint($max992) {
		margin: 10px 0;
		align-self: center;
	}
}