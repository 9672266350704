@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?9gwnzu');
  src:  url('#{$icomoon-font-path}/icomoon.eot?9gwnzu#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/icomoon.ttf?9gwnzu') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?9gwnzu') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?9gwnzu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.apaicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lokacija {
  &:before {
    content: $icon-lokacija;
  }
}
.icon-povrsina {
  &:before {
    content: $icon-povrsina;
  }
}
.icon-stanovnistvo {
  &:before {
    content: $icon-stanovnistvo;
  }
}
.icon-sunce {
  &:before {
    content: $icon-sunce;
  }
}
.icon-vetar {
  &:before {
    content: $icon-vetar;
  }
}

