.sidebar-widget {
	margin-bottom: 40px;

	@include breakpoint($max992){
		width: 50%;
		float: left;
	}
	@include breakpoint($max480){
		width: 100%;
		float: left;
	}
}

.sidebar-widget__nav {
	padding: 10px 0;
	li {
		a {
			color: $text_c;
			display: block;
			padding: 8px 15px;
			font-size: 16px;
			font-size: 1.6rem;
			position: relative;
			transition: background-color .2s ease-in-out;

			font-weight: 700;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -1px;
				height: 1px;
				background-color: $text_c;
				width: 200px;
				@include breakpoint($max480){
					width: 100%;
				}
			}
			&:hover, &:focus {
				background-color: $text_c;
				color: #fff;
				&:after {
					bottom: 0;
				}
			}
		}
		&:first-child {
			a {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 1px;
					background-color: $text_c;
					width: 200px;
					@include breakpoint($max480){
						width: 100%;
					}
				}
			}
		}
	}
}
.sidebar-widget-news{
	.sidebar-widget__body{
		background-image: url("../img/side-bar-news-BG-1.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		margin-right: -15px;
		padding-right: 15px;
		padding-bottom: 20px;
	}
}
.sidebar-widget__news-list {

	padding: 10px 0;
	li {
		a.news-prev {
			color: $text_c;
			display: block;
			min-height: 64px;
			padding: 8px 15px;
			margin-bottom: 0;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -1px;
				height: 1px;
				background-color: $text_c;
				width: 200px;
				@include breakpoint($max480){
					width: 100%;
				}
			}
			.news-prev__date {
				left: 15px;
				top: 8px;
			}
			&:hover, &:focus {
				background-color: $text_c;
				color: #fff;
				&:after {
					bottom: 0;
				}
			}
		}
		&:first-child {
			.news-prev {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 1px;
					background-color: $text_c;
					width: 200px;
					@include breakpoint($max480){
						width: 100%;
					}
				}
			}

		}
	}
}

.sidebar-widget__gallery-list{

}
.widget-banner{
	margin-bottom: 20px;
}