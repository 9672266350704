.apa-container {
	width: 100%;
	@include breakpoint($min1200) {
		width: 1170px;
	}

}

.abs-header .nav-apa {

	@include breakpoint($min992) {
		position: absolute;
		width: 100%;
		z-index: 500;
	}
}

/* lang-switch
-------------------------------------------------------*/

.nav_apa-top-line {
	height: 30px;
	background-color: $text_c;
	color: #fff;
	border-bottom: 1px solid $blue_c;
	.container {
		position: relative;
	}
}

@include breakpoint ($min768) {
	.lang-switch {
		position: absolute;
		right: 0;
		top: 0px;

	}
}

.lang-switch .lang {
	padding: 5px;
	&.active {
		//opacity: .5;
		background-color: $blue_c;
		.lang__item {
			&:hover, &:focus {
				color: #fff;
				cursor: default;
			}
		}

	}
	float: left;
	.lang__item {
		color: #fff;
		float: left;
		padding-left: 5px;
		&:hover, &:focus {
			color: $orange_c;
		}
	}
}

/* navbar
-------------------------------------------------------*/
.navbar {
	margin-bottom: 0;
	border: none;
	min-height: 60px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	z-index: 1200;
	background-color: rgba($black_bg_c, .9);
}

.navbar-nav {
	margin: 0;
	float: none;
	display: inline-block;
}

.navbar-nav > li > a {

	text-transform: uppercase;
	padding: 0 20px;
	line-height: 60px;
	color: #fff;
	font-size: 14px;
	letter-spacing: 0.02em;
	font-weight: bold;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	@include breakpoint($min992) {
		font-size: 12px;
	}
	@include breakpoint($min1200) {
		font-size: 13px;
	}
}

.navigation-overlay {
	background-color: rgba(17, 17, 17, 0.5);
	width: 100%;
	line-height: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.navigation-overlay.sticky {
	//background-color: #111111;
	visibility: visible;
	opacity: 1;
}

.navigation-overlay.sticky .navbar-nav > li > a,
.navigation-overlay.sticky .menu-socials li > a {
	line-height: 60px;
}

.navbar-header {

	padding-right: 15px;
	@include breakpoint($min992) {
		width: 25%;
	}
	@include breakpoint($min1200) {
		width: 28%;
	}
}

.nav-wrap {

	@include breakpoint($min992) {
		width: 75%;
	}
	@include breakpoint($min1200) {
		width: 72%;
	}

}

.logo-wrap {

}

.logo-wrap.shrink {
	img {
		position: static;
		float: left;
		padding-top: 5px;
		height: 52px;
	}
	#apa_site-name {

		margin-left: 10px;
	}
}

.navbar-collapse.in {
	overflow-x: hidden;
}

.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
	//opacity: 1;
	//color: #bfa67a !important;
	background-color: $blue_c;
	color: #fff;

}

.navbar-collapse {
	padding: 0;
	border-top: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav > li > a:focus,
.nav > li > a:hover {
	background-color: $blue_c;
	text-decoration: none;
	border-color: #f2f2f2;
}

@include breakpoint($max992) {

	.navigation-overlay,
	.navigation-overlay.sticky {
		background-color: rgba(17, 17, 17, 0.9);
	}
	.nav-wrap {
		width: 100%;
		padding: 0;
		min-height: 0;
	}
	.nav-type-1 .container-fluid {
		padding: 0 15px;
	}
	.navbar-header {
		width: 100%;
		padding-right: 0;
	}

	.navbar-nav {
		width: 100%;
		//padding: 40px 0;
	}
	.navigation-overlay.sticky .navbar-nav > li > a,
	.navigation-overlay .navbar-nav > li > a {
		padding: 10px 0;
		line-height: 30px;
	}

	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: lighten($text_c, 10%);
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		a {
			color: #fff;
		}
	}

}

.logo-wrap {
	float: left;
	position: relative;
	a {
		display: block;
		@extend .clearfix;
	}
	img {
		position: absolute;
		height: 44px;
		top: 5px;
		left: 10px;
	}
	#apa_site-name {
		float: left;
		//display: inline-block;
		text-transform: uppercase;
		font-weight: 700;
		color: #fff;
		line-height: 54px;
		font-size: 20px;
		margin-left: 60px;
	}
	@include breakpoint($min480) {
		img {
			height: 52px;
		}
		#apa_site-name {
			margin-left: 60px;
			line-height: 60px;
			font-size: 30px;
		}
	}
	@include breakpoint($min992) {
		img {

			top: -25px;
			left: 0;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			height: 80px;
		}

		#apa_site-name {
			float: left;
			text-transform: uppercase;
			font-weight: 700;
			color: #fff;
			line-height: 60px;
			font-size: 40px;
			margin-left: 70px;
		}
	}
}

.nav-apa .dropdown {

	& > a:after {
		font-family: "FontAwesome";
		position: absolute;
		content: "\f107";
		right: 15px;
		color: #fff;

	}
	//@include breakpoint($min992){
	//	& > a{
	//		padding-right: 20px;
	//	}
	//}

}

.nav-apa .navbar-nav > li > a.dropdown-toggle {
	padding-right: 32px;
	&:after {
		font-family: "FontAwesome";
		position: absolute;
		content: "\f107";
		right: 15px;
		color: #fff;

	}
}

.nav-apa .navbar-nav > li > a.external-link {
	padding-right: 32px;
	&:after {
		font-family: "FontAwesome";
		position: absolute;
		content: "\f105";
		right: 15px;
		color: #fff;

	}
}

.nav-apa .navbar {
	min-height: 55px;
	@include breakpoint($min480) {

		min-height: 60px;
	}
	@include breakpoint($min992) {

	}
}

.nav-apa .navbar-nav > li > a {
	padding: 0 10px;
	@include breakpoint($min992) {
		padding: 0 5px;
	}
	@include breakpoint($min1200) {
		padding: 0 10px;
	}
}

.nav-apa .navbar-toggle:focus .icon-bar,
.nav-apa .navbar-toggle:hover .icon-bar {
	background-color: #111;
}

.nav-apa .navbar-nav > li > a {
	color: #fff;
	-webkit-transition: color 0.3s ease-in-out;
	-moz-transition: color 0.3s ease-in-out;
	-ms-transition: color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out;
}

.nav-apa .navbar-toggle .icon-bar,
.nav-type-4 .navbar-toggle .icon-bar {
	background-color: $blue_c;
}

.navigation.offset {
	-webkit-transform: translate3d(0, -300px, 0);
	-moz-transform: translate3d(0, -300px, 0);
	-ms-transform: translate3d(0, -300px, 0);
	-o-transform: translate3d(0, -300px, 0);
	transform: translate3d(0, -300px, 0);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.navigation.sticky {
	position: fixed;
	background-color: rgba($black_bg_c, .9);
	visibility: hidden;
	opacity: 0;
	width: 100%;
	height: 60px;
	top: 0;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	z-index: 1009;
}

.navigation.scrolling {
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	-ms-transform: translate3d(0, 0px, 0);
	-o-transform: translate3d(0, 0px, 0);
	transform: translate3d(0, 0px, 0);
}

.navigation.sticky.scrolling {
	opacity: 1;
	visibility: visible;
}

.navigation.sticky .navbar-nav > li > a {
	line-height: 60px;
}

/* Menu Search
-------------------------------------------------------*/
.navbar-nav > li > a.nav-search {
	position: relative;
	display: none;
	margin: 23px 0 22px;
	width: 30px;
	height: 15px;
	padding-left: 10px;
	line-height: 60px;
	&:hover, &:focus {
		background-color: transparent;
		color: $orange_c;
	}
	@include breakpoint($min992) {
		display: block;
	}
}

.navigation.sticky .navbar-nav > li > a.nav-search {
	margin: 23px 0 22px;
}

.search-wrap {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;
}

.search-wrap .form-control {
	height: 100%;
	border: none;
	font-size: 24px;
}

.search-wrap input,
.search-wrap input:focus {
	background-color: #fff !important;
	border: none;
}

.search-trigger {
	z-index: 22;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	//right: 0;
	//opacity: 1;
	display: block;
}

.search-close {
	//opacity: 0;
	display: none;

	font-size: 16px;
	z-index: 21;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 0;
	color: $black_bg_c;
	&:hover, &:focus {
		background-color: transparent;
		color: $orange_c;
	}
}

.nav-search.open .search-trigger {
	display: none;
	z-index: 20;
}

.nav-search.open .search-close {
	opacity: 1;
	display: block;
}

#mobile-search {
	display: block;
	@include breakpoint($min992) {

		display: none;
	}
}

/* dropdown-menu
-------------------------------------------------------*/
.nav-apa .dropdown-menu {
	left: 0;
	right: auto;
	& > li > a:hover {
		background-color: rgba($blue_c, .8);
		color: #fff;
	}
}
