.og_ko-item {
	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e5e5e5;
	&:last-child {
		border-bottom: none;
	}
	&.finished{
		border: 1px solid #e5e5e5;
		background-color: #f7f7f7;
		opacity: .8;
		padding: 10px;
	}
}

.og_ko-item-header {
	position: relative;
	min-height: 48px;
	margin-bottom: 10px;
}

.og_ko-title {
	font-size: 16px;
	margin-bottom: 8px;
	text-transform: none;
	letter-spacing: normal;
}

.og_ko-date {
	display: inline-block;
	border: 4px solid $text_c;

	text-align: center;
	@extend .clearfix;
	.og_ko-date-item {
		float: left;
		font-weight: 700;
		padding: 3px 5px;
		&.og_ko-date-text {
			border-left: 4px solid $text_c;
			border-right: 4px solid $text_c;
			background-color: $text_c;
			color: #fff;
		}

	}
}

.og_ko-text {
	margin-bottom: 15px;
}

.doc_download-title {
	margin-bottom: 10px;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.1;
}

.doc_download-list {
	margin: 0;
}

.og_ko-search {
	padding: 20px 20px 4px 20px;
	border: 1px solid #e5e5e5;
	margin-bottom: 30px;
	background-color: #f7f7f7;
}

.og_ko-search {
	#og_ko_name, #daterange {
		background-color: #fff;

	}
}

.og_ko-form-btn-wrap {
	padding-top: 25px;
}