html {
	min-height: 100%;
	font-size: 62.5%;
}

body {
	font-family: 'Open Sans', sans-serif;
	color: $text_c;
	font-size: 14px;
	font-size: 1.4rem;
	font-weight: 400;
	img {
		max-width: 100%;
		display: block;
	}
}

#map {
	height: 400px;
	width: 100%;
}

/*-------------------------------------------------------*/
/* Page with sidebar layout
/*-------------------------------------------------------*/
.main-page-container {
	@extend .clearfix;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
}

@include breakpoint($min992){
	.page-wrap {
		display: table;
		width: 100%;
	}

	.page-content {
		display: table-cell;
		padding-right: 30px;
		padding-left: 15px;
	}

	.page-sidebar {
		border-left: 1px solid $text_c;
		display: table-cell;
		width: 300px;
	}
}


.main-page-container {
	background-color: #fff;
}

.page_n_sidebar {
	.page-content {
		display: block;
		padding-right: 15px;
		padding-left: 15px;
	}
}

/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
.clearfix {
	*zoom: 1;
}

.clearfix:before, .clearfix:after {
	display: table;
	line-height: 0;
	content: "";
}

.clearfix:after {
	clear: both;
}

.clear {
	clear: both;
}

.oh {
	overflow: hidden;
}

.relative {
	position: relative;
}

.section-wrap, .section-wrap-mp {
	padding: 150px 0;
	overflow: hidden;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.section-wrap-mp {
	padding: 100px 0;
}

.section-wrap-sm {
	padding: 50px 0;
}

.color-white {
	color: #fff;
}

.left {
	float: left;
}

.right {
	float: right;
}

.bg-light {
	background-color: #f7f7f7;
	background-image: url("../img/page-Grb-BG-1.png");
	background-repeat: no-repeat;
	background-position: top ;
	.main-page-container{
		background-color: rgba(#fff, .6);
	}
}
.index-page{
	.bg-light{
		background-image: none;
	}
}


.bg-dark {
	background-color: #242424;
}

.last {
	margin-bottom: 0 !important;
}

::-moz-selection {
	color: #fff;
	background: #000;
}

::-webkit-selection {
	color: #fff;
	background: #000;
}

::selection {
	color: #fff;
	background: #000;
}

a {
	text-decoration: none;
	color: $orange_c;
	outline: none;
	-webkit-transition: color 0.2s ease-in-out;
	-moz-transition: color 0.2s ease-in-out;
	-ms-transition: color 0.2s ease-in-out;
	-o-transition: color 0.2s ease-in-out;
	transition: color 0.2s ease-in-out;
}

a:hover, a:focus {
	text-decoration: none;
	color: $blue_c;
	outline: none;
}

:focus {
	outline: none;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

body {
	//margin: 0;
	//padding: 0;
	//
	//color: #7a7a7a;
	//font-size: 15px;
	//line-height: 1.5;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	//background: #fff;
	//outline: 0;
	//overflow-x: hidden;
	//overflow-y: auto;
}

video {
	height: 100%;
	width: 100%;
}

body img {
	border: none;
	max-width: 100%;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

h1, h2, h3, h4, h5, h6 {

	margin-top: 0;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-weight: 700;
}

h1 {
	font-size: 28px;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 21px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 15px;
}

p {
	font-size: 14px;
	font-size: 1.4rem;

	font-weight: normal;

}

.bottom-line:after {
	content: "";
	display: block;
	width: 48px;
	border-bottom: 3px solid #e8e8e8;
	margin: 15px auto 15px;
}

/* Change the color values to your color */

a,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.overlay-menu ul li.active > a,
.overlay-menu ul li a:hover,
.icon-effect-1 .hi-icon,
.contact-icon i,
.owl-prev:hover i,
.owl-next:hover i,
.works-grid.titles .work-description a:hover,
#owl-related-works .work-description a:hover,
.portfolio-description ul > li a:hover {
	color: $orange_c;
}

.socials a:hover i,
.meter .progress-bar,
.project-icons a:hover,
.best-price,
.related-projects .customNavigation a:hover,
.highlight,
.btn:hover,
.socials.footer-socials a:hover i {
	background-color: $orange_c;
}

.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
	color: #fff;
}

.menu-socials li > a:hover,
.icon-effect-1 .hi-icon,
.contact-icon,
input.searchbox:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
	border-color: $blue_c;
}

.dropdown-menu {
	border-top-color: #bfa67a;
}

/*  Go to Top
----------------------------------------------------------------------------------------------------------------------*/
#back-to-top {
	display: block;
	z-index: 500;
	width: 40px;
	height: 40px;
	text-align: center;
	font-size: 22px;
	position: fixed;
	bottom: -40px;
	right: 20px;
	line-height: 35px;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	background-color: #fff;
	text-decoration: none;
	box-shadow: 1px 1.732px 12px 0px rgba(0, 0, 0, 0.14), 1px 1.732px 3px 0px rgba(0, 0, 0, 0.12);
}

#back-to-top i {
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

#back-to-top a {
	width: 40px;
	height: 40px;
	display: block;
	color: $text_c;
}

#back-to-top.show {
	bottom: 20px;
}

#back-to-top:hover {
	background-color: $text_c;
}

#back-to-top:hover i {
	color: #fff;
}

/*  Pagination
----------------------------------------------------------------------------------------------------------------------*/
.pagination {
	margin: 0 auto;
	border-radius: 0;
	display: block;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 50px;
	& > i {
		font-size: 14px;
		vertical-align: middle;
	}
	i:first-child {
		margin-right: -5px;
	}
	i:last-child {
		margin-left: -7px;
	}
	a, span {
		background-color: transparent;
		font-size: 12px;
		display: inline-block;
		//height: 25px;
		line-height: 20px;
		text-align: center;
		margin: 0 9px;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		font-family: "Montserrat", sans-serif;
		font-weight: bold;
		text-transform: uppercase;
	}
	a {
		color: $text_c;
	}
	.current {
		color: $orange_c;
	}
	.pagination span.pagination-dots {
		margin: 0 3px;
	}
}









