.title-2 {
	margin: 0 0 10px 0;
	font-size: 24px;
	font-weight: 800;
}

.footer-col-title {
	margin: 0 0 20px 0;
	color: #fff;
}
.page-title{
	margin: 0;
	font-weight: 800;
	font-size: 18px;
	font-size: 1.8rem;

	@include breakpoint($min768){
		font-size: 24px;
		font-size: 2.4rem;
	}
	@include breakpoint($min992){
		font-size: 32px;
		font-size: 3.2rem;
	}
}
.sidebar-widget__title{
	padding-left: 15px;
	margin: 0;
	font-weight: 800;
	font-size: 18px;
	font-size: 1.8rem;
	line-height: 1.2;
	a{
		color: $text_c;
		&:hover,&:focus{
			text-decoration: none;
			color: $orange_c;
		}
	}
	@include breakpoint($min768){
		font-size: 24px;
		font-size: 2.4rem;
	}

}


.search-prev__title{
	margin-bottom: 10px;

}
.box_list__title{

}