/* Contact
-------------------------------------------------------*/
.contact-row {
	margin-left: -8px;
	margin-right: -8px;
}

.contact-name,
.contact-email {
	padding: 0 8px;
}

.contact-item {
	position: relative;
	padding-left: 70px;
	padding-top: 5px;
	margin-top: 30px;
}

.contact-item:first-of-type {
	margin-top: 38px;
}

.contact-item h6 {
	font-size: 12px;
	margin-top: 5px;
	margin-bottom: 7px;
}
.contact-name-title{
	margin-bottom: 5px;
}
.contact-work-hour{
	font-size: 18px;
	//font-weight: 700;
}
#contact p{
	margin: 0;
}
.contact-item p,
.contact-item span,
.contact-item a {
	font-size: 16px;
}

.contact-item a:hover {
	color: #111;
}

.contact-icon {
	width: 50px;
	height: 50px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	border: 2px solid $text_c;
	text-align: center;
	position: absolute;
	left: 0;
}

.contact-icon i {
	font-size: 18px;
	color: $text_c;
	line-height: 47px;
}

.gmap {
	position: relative;
	width: 100%;
	height: 450px;
}

#contact-form .message {
	height: 50px;
	width: 100%;
	font-size: 13px;
	line-height: 50px;
	text-align: center;
	float: none;
	margin-top: 20px;
	display: none;
	color: #fff;
}

#contact-form .message.error {
	background-color: #f44336;
}

#contact-form .message.success {
	background-color: #4CAF50;
}









/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"],
select,
textarea {
	position: relative;
	height: 47px;
	line-height: 47px;
	border: 2px solid #e3e3e3;
	background-color: transparent;
	width: 100%;
	margin-bottom: 16px;
	padding: 0 16px;
	color: #7a7a7a;
	-webkit-transition: border-color 0.3s ease-in-out;
	-moz-transition: border-color 0.3s ease-in-out;
	-ms-transition: border-color 0.3s ease-in-out;
	-o-transition: border-color 0.3s ease-in-out;
	transition: border-color 0.3s ease-in-out;
}

textarea#comment {
	height: 190px;
	padding: 0 16px;
	margin-bottom: 10px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
	border-color: $orange_c;
	outline: none;
	box-shadow: none;
}

textarea {
	height: auto;
	padding: 0px 16px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #7a7a7a;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
	color: #7a7a7a;
	opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #7a7a7a;
	opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #7a7a7a;
}

select {
	line-height: 1;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
}

select::-ms-expand {
	display: none;
}

.select {
	position: relative;
	cursor: pointer;
}

.select i {
	position: absolute;
	top: 14px;
	right: 18px;
	pointer-events: none;
	font-size: 18px;
}
.form-desc{
	color: rgba($text_c, .5);
	margin-bottom: 15px;
}