/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 99999;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 5px;
	width: 5em;
	height: 5em;
	margin: -25px 0 0 -25px;
	text-indent: -9999em;
	border-top: 0.5em solid #121212;
	border-right: 0.5em solid rgba(245, 245, 245, 0.5);
	border-bottom: 0.5em solid rgba(245, 245, 245, 0.5);
	border-left: 0.5em solid rgba(245, 245, 245, 0.5);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
	-webkit-border-radius: 50%;
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media (max-width: 991px) {
	.navbar-header {
		float: none;
	}
	.navbar-left, .navbar-nav, .navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-nav {
		margin-top: 7.5px;
	}
	.navbar-nav > li {
		float: none;
	}
	.navbar-nav > li > a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in {
		display: block !important;
	}

}
