/***
====================================================================
	Colors
====================================================================
 ***/

$blue_c: #306dab;
$orange_c: #f35339;
$text_c:#222b34;
$black_bg_c:#1d1d1d;


/***
====================================================================
	clearfix
====================================================================
 ***/
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
@for $i from 1 through 10 {
	.spacer#{$i}0 {
		height: #{$i}0px;
	}
}
/***
====================================================================
	media queries
====================================================================
 ***/
$min400: 400px;
$max400: max-width 399px;
$min480: 480px;
$max480: max-width 479px;
$min768: 768px;
$max768: max-width 767px;
$min992: 992px;
$max992: max-width 991px;
$min1200: 1200px;
$max1200: max-width 1199px;
$min1600: 1600px;
$max1600: max-width 1599px;