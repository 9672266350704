@charset "UTF-8";
/***
====================================================================
	Colors
====================================================================
 ***/
/***
====================================================================
	clearfix
====================================================================
 ***/
.clearfix:after, .main-page-container:after, .logo-wrap a:after, .position-item-half-wrap:after, .news-prev-large__header a:after, .sidebar__works-grid:after, .og_ko-date:after {
  content: "";
  display: table;
  clear: both; }

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
.spacer10 {
  height: 10px; }

.spacer20 {
  height: 20px; }

.spacer30 {
  height: 30px; }

.spacer40 {
  height: 40px; }

.spacer50 {
  height: 50px; }

.spacer60 {
  height: 60px; }

.spacer70 {
  height: 70px; }

.spacer80 {
  height: 80px; }

.spacer90 {
  height: 90px; }

.spacer100 {
  height: 100px; }

/***
====================================================================
	media queries
====================================================================
 ***/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9gwnzu");
  src: url("../fonts/icomoon.eot?9gwnzu#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9gwnzu") format("truetype"), url("../fonts/icomoon.woff?9gwnzu") format("woff"), url("../fonts/icomoon.svg?9gwnzu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.apaicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-lokacija:before {
  content: ""; }

.icon-povrsina:before {
  content: ""; }

.icon-stanovnistvo:before {
  content: ""; }

.icon-sunce:before {
  content: ""; }

.icon-vetar:before {
  content: ""; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 0;
  width: 278px;
  padding: 4px;
  margin-top: 1px;
  top: 100px;
  left: 20px;
  /* Calendars */ }
  .daterangepicker:before, .daterangepicker:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: ''; }
  .daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc; }
  .daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent; }
  .daterangepicker.opensleft:before {
    right: 9px; }
  .daterangepicker.opensleft:after {
    right: 10px; }
  .daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto; }
  .daterangepicker.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto; }
  .daterangepicker.opensright:before {
    left: 9px; }
  .daterangepicker.opensright:after {
    left: 10px; }
  .daterangepicker.dropup {
    margin-top: -5px; }
    .daterangepicker.dropup:before {
      top: initial;
      bottom: -7px;
      border-bottom: initial;
      border-top: 7px solid #ccc; }
    .daterangepicker.dropup:after {
      top: initial;
      bottom: -6px;
      border-bottom: initial;
      border-top: 6px solid #fff; }
  .daterangepicker.dropdown-menu {
    max-width: none;
    z-index: 3001; }
  .daterangepicker.single .ranges, .daterangepicker.single .calendar {
    float: none; }
  .daterangepicker.show-calendar .calendar {
    display: block; }
  .daterangepicker .calendar {
    display: none;
    max-width: 270px;
    margin: 4px; }
    .daterangepicker .calendar.single .calendar-table {
      border: none; }
    .daterangepicker .calendar th, .daterangepicker .calendar td {
      white-space: nowrap;
      text-align: center;
      min-width: 32px; }
  .daterangepicker .calendar-table {
    border: 1px solid #fff;
    padding: 4px;
    border-radius: 0;
    background-color: #fff; }
  .daterangepicker table {
    width: 100%;
    margin: 0; }
  .daterangepicker td, .daterangepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 0;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer; }
    .daterangepicker td.available:hover, .daterangepicker th.available:hover {
      background-color: #eee;
      border-color: transparent;
      color: inherit; }
    .daterangepicker td.week, .daterangepicker th.week {
      font-size: 80%;
      color: #ccc; }
  .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #fff;
    border-color: transparent;
    color: #999; }
  .daterangepicker td.in-range {
    background-color: #ebf4f8;
    border-color: transparent;
    color: #000;
    border-radius: 0; }
  .daterangepicker td.start-date {
    border-radius: 0 0 0 0; }
  .daterangepicker td.end-date {
    border-radius: 0 0 0 0; }
  .daterangepicker td.start-date.end-date {
    border-radius: 0; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #357ebd;
    border-color: transparent;
    color: #fff; }
  .daterangepicker th.month {
    width: auto; }
  .daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through; }
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default; }
  .daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%; }
  .daterangepicker select.yearselect {
    width: 40%; }
  .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
    width: 50px;
    margin-bottom: 0; }
  .daterangepicker .input-mini {
    border: 1px solid #ccc;
    border-radius: 0;
    color: #555;
    height: 30px;
    line-height: 30px;
    display: block;
    vertical-align: middle;
    margin: 0 0 5px 0;
    padding: 0 6px 0 28px;
    width: 100%; }
    .daterangepicker .input-mini.active {
      border: 1px solid #08c;
      border-radius: 0; }
  .daterangepicker .daterangepicker_input {
    position: relative; }
    .daterangepicker .daterangepicker_input i {
      position: absolute;
      left: 8px;
      top: 8px; }
  .daterangepicker.rtl .input-mini {
    padding-right: 28px;
    padding-left: 6px; }
  .daterangepicker.rtl .daterangepicker_input i {
    left: auto;
    right: 8px; }
  .daterangepicker .calendar-time {
    text-align: center;
    margin: 5px auto;
    line-height: 30px;
    position: relative;
    padding-left: 28px; }
    .daterangepicker .calendar-time select.disabled {
      color: #ccc;
      cursor: not-allowed; }

.ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left; }
  .ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%; }
  .ranges li {
    font-size: 13px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 0;
    color: #08c;
    padding: 3px 12px;
    margin-bottom: 8px;
    cursor: pointer; }
    .ranges li:hover {
      background-color: #08c;
      border: 1px solid #08c;
      color: #fff; }
    .ranges li.active {
      background-color: #08c;
      border: 1px solid #08c;
      color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto; }
    .daterangepicker .ranges ul {
      width: 160px; }
    .daterangepicker.single .ranges ul {
      width: 100%; }
    .daterangepicker.single .calendar.left {
      clear: none; }
    .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .calendar {
      float: left; }
    .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .calendar {
      float: right; }
    .daterangepicker.ltr {
      direction: ltr;
      text-align: left; }
      .daterangepicker.ltr .calendar.left {
        clear: left;
        margin-right: 0; }
        .daterangepicker.ltr .calendar.left .calendar-table {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .daterangepicker.ltr .calendar.right {
        margin-left: 0; }
        .daterangepicker.ltr .calendar.right .calendar-table {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .daterangepicker.ltr .left .daterangepicker_input {
        padding-right: 12px; }
      .daterangepicker.ltr .calendar.left .calendar-table {
        padding-right: 12px; }
      .daterangepicker.ltr .ranges, .daterangepicker.ltr .calendar {
        float: left; }
    .daterangepicker.rtl {
      direction: rtl;
      text-align: right; }
      .daterangepicker.rtl .calendar.left {
        clear: right;
        margin-left: 0; }
        .daterangepicker.rtl .calendar.left .calendar-table {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .daterangepicker.rtl .calendar.right {
        margin-right: 0; }
        .daterangepicker.rtl .calendar.right .calendar-table {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .daterangepicker.rtl .left .daterangepicker_input {
        padding-left: 12px; }
      .daterangepicker.rtl .calendar.left .calendar-table {
        padding-left: 12px; }
      .daterangepicker.rtl .ranges, .daterangepicker.rtl .calendar {
        text-align: right;
        float: right; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto; }
  .daterangepicker.ltr .ranges {
    float: left; }
  .daterangepicker.rtl .ranges {
    float: right; }
  .daterangepicker .calendar.left {
    clear: none !important; } }

html {
  min-height: 100%;
  font-size: 62.5%; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #222b34;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400; }
  body img {
    max-width: 100%;
    display: block; }

#map {
  height: 400px;
  width: 100%; }

/*-------------------------------------------------------*/
/* Page with sidebar layout
/*-------------------------------------------------------*/
.main-page-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

@media (min-width: 992px) {
  .page-wrap {
    display: table;
    width: 100%; }
  .page-content {
    display: table-cell;
    padding-right: 30px;
    padding-left: 15px; }
  .page-sidebar {
    border-left: 1px solid #222b34;
    display: table-cell;
    width: 300px; } }

.main-page-container {
  background-color: #fff; }

.page_n_sidebar .page-content {
  display: block;
  padding-right: 15px;
  padding-left: 15px; }

/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
.clearfix, .main-page-container, .logo-wrap a, .position-item-half-wrap, .news-prev-large__header a, .sidebar__works-grid, .og_ko-date {
  *zoom: 1; }

.clearfix:before, .main-page-container:before, .logo-wrap a:before, .position-item-half-wrap:before, .news-prev-large__header a:before, .sidebar__works-grid:before, .og_ko-date:before, .clearfix:after, .main-page-container:after, .logo-wrap a:after, .position-item-half-wrap:after, .news-prev-large__header a:after, .sidebar__works-grid:after, .og_ko-date:after {
  display: table;
  line-height: 0;
  content: ""; }

.clearfix:after, .main-page-container:after, .logo-wrap a:after, .position-item-half-wrap:after, .news-prev-large__header a:after, .sidebar__works-grid:after, .og_ko-date:after {
  clear: both; }

.clear {
  clear: both; }

.oh {
  overflow: hidden; }

.relative {
  position: relative; }

.section-wrap, .section-wrap-mp {
  padding: 150px 0;
  overflow: hidden;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.section-wrap-mp {
  padding: 100px 0; }

.section-wrap-sm {
  padding: 50px 0; }

.color-white {
  color: #fff; }

.left {
  float: left; }

.right {
  float: right; }

.bg-light {
  background-color: #f7f7f7;
  background-image: url("../img/page-Grb-BG-1.png");
  background-repeat: no-repeat;
  background-position: top; }
  .bg-light .main-page-container {
    background-color: rgba(255, 255, 255, 0.6); }

.index-page .bg-light {
  background-image: none; }

.bg-dark {
  background-color: #242424; }

.last {
  margin-bottom: 0 !important; }

::-moz-selection {
  color: #fff;
  background: #000; }

::-webkit-selection {
  color: #fff;
  background: #000; }

::selection {
  color: #fff;
  background: #000; }

a {
  text-decoration: none;
  color: #f35339;
  outline: none;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out; }

a:hover, a:focus {
  text-decoration: none;
  color: #306dab;
  outline: none; }

:focus {
  outline: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

body {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased; }

video {
  height: 100%;
  width: 100%; }

body img {
  border: none;
  max-width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 700; }

h1 {
  font-size: 28px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 21px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 15px; }

p {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: normal; }

.bottom-line:after {
  content: "";
  display: block;
  width: 48px;
  border-bottom: 3px solid #e8e8e8;
  margin: 15px auto 15px; }

/* Change the color values to your color */
a,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.overlay-menu ul li.active > a,
.overlay-menu ul li a:hover,
.icon-effect-1 .hi-icon,
.contact-icon i,
.owl-prev:hover i,
.owl-next:hover i,
.works-grid.titles .work-description a:hover,
#owl-related-works .work-description a:hover,
.portfolio-description ul > li a:hover {
  color: #f35339; }

.socials a:hover i,
.meter .progress-bar,
.project-icons a:hover,
.best-price,
.related-projects .customNavigation a:hover,
.highlight,
.btn:hover,
.socials.footer-socials a:hover i {
  background-color: #f35339; }

.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
  color: #fff; }

.menu-socials li > a:hover,
.icon-effect-1 .hi-icon,
.contact-icon,
input.searchbox:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
  border-color: #306dab; }

.dropdown-menu {
  border-top-color: #bfa67a; }

/*  Go to Top
----------------------------------------------------------------------------------------------------------------------*/
#back-to-top {
  display: block;
  z-index: 500;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 22px;
  position: fixed;
  bottom: -40px;
  right: 20px;
  line-height: 35px;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  background-color: #fff;
  text-decoration: none;
  box-shadow: 1px 1.732px 12px 0px rgba(0, 0, 0, 0.14), 1px 1.732px 3px 0px rgba(0, 0, 0, 0.12); }

#back-to-top i {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }

#back-to-top a {
  width: 40px;
  height: 40px;
  display: block;
  color: #222b34; }

#back-to-top.show {
  bottom: 20px; }

#back-to-top:hover {
  background-color: #222b34; }

#back-to-top:hover i {
  color: #fff; }

/*  Pagination
----------------------------------------------------------------------------------------------------------------------*/
.pagination {
  margin: 0 auto;
  border-radius: 0;
  display: block;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 50px; }
  .pagination > i {
    font-size: 14px;
    vertical-align: middle; }
  .pagination i:first-child {
    margin-right: -5px; }
  .pagination i:last-child {
    margin-left: -7px; }
  .pagination a, .pagination span {
    background-color: transparent;
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    margin: 0 9px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-transform: uppercase; }
  .pagination a {
    color: #222b34; }
  .pagination .current {
    color: #f35339; }
  .pagination .pagination span.pagination-dots {
    margin: 0 3px; }

/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999; }

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5px;
  width: 5em;
  height: 5em;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  border-top: 0.5em solid #121212;
  border-right: 0.5em solid rgba(245, 245, 245, 0.5);
  border-bottom: 0.5em solid rgba(245, 245, 245, 0.5);
  border-left: 0.5em solid rgba(245, 245, 245, 0.5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

.loader,
.loader:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 10em;
  height: 10em; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-left, .navbar-nav, .navbar-right {
    float: none !important; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    margin-top: 7.5px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collapse.in {
    display: block !important; } }

.page-header {
  position: relative;
  z-index: 50; }

.apa-container {
  width: 100%; }
  @media (min-width: 1200px) {
    .apa-container {
      width: 1170px; } }

@media (min-width: 992px) {
  .abs-header .nav-apa {
    position: absolute;
    width: 100%;
    z-index: 500; } }

/* lang-switch
-------------------------------------------------------*/
.nav_apa-top-line {
  height: 30px;
  background-color: #222b34;
  color: #fff;
  border-bottom: 1px solid #306dab; }
  .nav_apa-top-line .container {
    position: relative; }

@media (min-width: 768px) {
  .lang-switch {
    position: absolute;
    right: 0;
    top: 0px; } }

.lang-switch .lang {
  padding: 5px;
  float: left; }
  .lang-switch .lang.active {
    background-color: #306dab; }
    .lang-switch .lang.active .lang__item:hover, .lang-switch .lang.active .lang__item:focus {
      color: #fff;
      cursor: default; }
  .lang-switch .lang .lang__item {
    color: #fff;
    float: left;
    padding-left: 5px; }
    .lang-switch .lang .lang__item:hover, .lang-switch .lang .lang__item:focus {
      color: #f35339; }

/* navbar
-------------------------------------------------------*/
.navbar {
  margin-bottom: 0;
  border: none;
  min-height: 60px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1200;
  background-color: rgba(29, 29, 29, 0.9); }

.navbar-nav {
  margin: 0;
  float: none;
  display: inline-block; }

.navbar-nav > li > a {
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 60px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: bold;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 992px) {
    .navbar-nav > li > a {
      font-size: 12px; } }
  @media (min-width: 1200px) {
    .navbar-nav > li > a {
      font-size: 13px; } }

.navigation-overlay {
  background-color: rgba(17, 17, 17, 0.5);
  width: 100%;
  line-height: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.navigation-overlay.sticky {
  visibility: visible;
  opacity: 1; }

.navigation-overlay.sticky .navbar-nav > li > a,
.navigation-overlay.sticky .menu-socials li > a {
  line-height: 60px; }

.navbar-header {
  padding-right: 15px; }
  @media (min-width: 992px) {
    .navbar-header {
      width: 25%; } }
  @media (min-width: 1200px) {
    .navbar-header {
      width: 28%; } }

@media (min-width: 992px) {
  .nav-wrap {
    width: 75%; } }

@media (min-width: 1200px) {
  .nav-wrap {
    width: 72%; } }

.logo-wrap.shrink img {
  position: static;
  float: left;
  padding-top: 5px;
  height: 52px; }

.logo-wrap.shrink #apa_site-name {
  margin-left: 10px; }

.navbar-collapse.in {
  overflow-x: hidden; }

.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
  background-color: #306dab;
  color: #fff; }

.navbar-collapse {
  padding: 0;
  border-top: none; }

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: #306dab;
  text-decoration: none;
  border-color: #f2f2f2; }

@media (max-width: 991px) {
  .navigation-overlay,
  .navigation-overlay.sticky {
    background-color: rgba(17, 17, 17, 0.9); }
  .nav-wrap {
    width: 100%;
    padding: 0;
    min-height: 0; }
  .nav-type-1 .container-fluid {
    padding: 0 15px; }
  .navbar-header {
    width: 100%;
    padding-right: 0; }
  .navbar-nav {
    width: 100%; }
  .navigation-overlay.sticky .navbar-nav > li > a,
  .navigation-overlay .navbar-nav > li > a {
    padding: 10px 0;
    line-height: 30px; }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #364553;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .navbar-nav .open .dropdown-menu a {
      color: #fff; } }

.logo-wrap {
  float: left;
  position: relative; }
  .logo-wrap a {
    display: block; }
  .logo-wrap img {
    position: absolute;
    height: 44px;
    top: 5px;
    left: 10px; }
  .logo-wrap #apa_site-name {
    float: left;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    line-height: 54px;
    font-size: 20px;
    margin-left: 60px; }
  @media (min-width: 480px) {
    .logo-wrap img {
      height: 52px; }
    .logo-wrap #apa_site-name {
      margin-left: 60px;
      line-height: 60px;
      font-size: 30px; } }
  @media (min-width: 992px) {
    .logo-wrap img {
      top: -25px;
      left: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      height: 80px; }
    .logo-wrap #apa_site-name {
      float: left;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      line-height: 60px;
      font-size: 40px;
      margin-left: 70px; } }

.nav-apa .dropdown > a:after {
  font-family: "FontAwesome";
  position: absolute;
  content: "\f107";
  right: 15px;
  color: #fff; }

.nav-apa .navbar-nav > li > a.dropdown-toggle {
  padding-right: 32px; }
  .nav-apa .navbar-nav > li > a.dropdown-toggle:after {
    font-family: "FontAwesome";
    position: absolute;
    content: "\f107";
    right: 15px;
    color: #fff; }

.nav-apa .navbar-nav > li > a.external-link {
  padding-right: 32px; }
  .nav-apa .navbar-nav > li > a.external-link:after {
    font-family: "FontAwesome";
    position: absolute;
    content: "\f105";
    right: 15px;
    color: #fff; }

.nav-apa .navbar {
  min-height: 55px; }
  @media (min-width: 480px) {
    .nav-apa .navbar {
      min-height: 60px; } }

.nav-apa .navbar-nav > li > a {
  padding: 0 10px; }
  @media (min-width: 992px) {
    .nav-apa .navbar-nav > li > a {
      padding: 0 5px; } }
  @media (min-width: 1200px) {
    .nav-apa .navbar-nav > li > a {
      padding: 0 10px; } }

.nav-apa .navbar-toggle:focus .icon-bar,
.nav-apa .navbar-toggle:hover .icon-bar {
  background-color: #111; }

.nav-apa .navbar-nav > li > a {
  color: #fff;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out; }

.nav-apa .navbar-toggle .icon-bar,
.nav-type-4 .navbar-toggle .icon-bar {
  background-color: #306dab; }

.navigation.offset {
  -webkit-transform: translate3d(0, -300px, 0);
  -moz-transform: translate3d(0, -300px, 0);
  -ms-transform: translate3d(0, -300px, 0);
  -o-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.navigation.sticky {
  position: fixed;
  background-color: rgba(29, 29, 29, 0.9);
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 60px;
  top: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1009; }

.navigation.scrolling {
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0); }

.navigation.sticky.scrolling {
  opacity: 1;
  visibility: visible; }

.navigation.sticky .navbar-nav > li > a {
  line-height: 60px; }

/* Menu Search
-------------------------------------------------------*/
.navbar-nav > li > a.nav-search {
  position: relative;
  display: none;
  margin: 23px 0 22px;
  width: 30px;
  height: 15px;
  padding-left: 10px;
  line-height: 60px; }
  .navbar-nav > li > a.nav-search:hover, .navbar-nav > li > a.nav-search:focus {
    background-color: transparent;
    color: #f35339; }
  @media (min-width: 992px) {
    .navbar-nav > li > a.nav-search {
      display: block; } }

.navigation.sticky .navbar-nav > li > a.nav-search {
  margin: 23px 0 22px; }

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20; }

.search-wrap .form-control {
  height: 100%;
  border: none;
  font-size: 24px; }

.search-wrap input,
.search-wrap input:focus {
  background-color: #fff !important;
  border: none; }

.search-trigger {
  z-index: 22;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  display: block; }

.search-close {
  display: none;
  font-size: 16px;
  z-index: 21;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 0;
  color: #1d1d1d; }
  .search-close:hover, .search-close:focus {
    background-color: transparent;
    color: #f35339; }

.nav-search.open .search-trigger {
  display: none;
  z-index: 20; }

.nav-search.open .search-close {
  opacity: 1;
  display: block; }

#mobile-search {
  display: block; }
  @media (min-width: 992px) {
    #mobile-search {
      display: none; } }

/* dropdown-menu
-------------------------------------------------------*/
.nav-apa .dropdown-menu {
  left: 0;
  right: auto; }
  .nav-apa .dropdown-menu > li > a:hover {
    background-color: rgba(48, 109, 171, 0.8);
    color: #fff; }

.footer-inner {
  background-image: url("../img/footer-bg-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff; }
  .footer-inner p {
    color: #fff; }
  .footer-inner a {
    color: #fff; }
    .footer-inner a:hover, .footer-inner a:focus {
      color: #f35339; }

.footer-widgets {
  margin: 30px 0 20px 0; }

.footer-get-in-touch p {
  font-size: 18px;
  font-weight: 700; }

.footer-links li a {
  color: #fff;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
  padding-bottom: 5px; }

/*  bottom-footer
----------------------------------------------------------------------------------------------------------------------*/
.bottom-footer {
  background-color: #222b34;
  border-top: 1px solid #306dab;
  color: #fff;
  text-align: center; }
  .bottom-footer a {
    color: #fff; }
    .bottom-footer a:hover, .bottom-footer a:focus {
      color: #f35339; }

.footer-signature,
.footer-copyright {
  font-size: 14px;
  line-height: 20px; }
  @media (min-width: 992px) {
    .footer-signature,
    .footer-copyright {
      line-height: 90px;
      opacity: .8; } }

.footer-signature .apaone {
  background-image: url(../img/apaone-logo.png);
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: left center;
  text-decoration: none; }

.footer-socials a {
  display: inline-block;
  padding: 0 15px;
  font-size: 20px;
  line-height: 40px; }
  @media (min-width: 992px) {
    .footer-socials a {
      font-size: 60px;
      line-height: 90px; } }

@media (max-width: 991px) {
  .bottom-footer .row {
    display: flex;
    align-items: center; } }

@media (max-width: 767px) {
  .bottom-footer .row {
    display: block; } }

@media (max-width: 991px) {
  .bottom-footer__col {
    margin: 10px 0;
    align-self: center; } }

.inner-section-container {
  padding: 30px 0; }
  @media (min-width: 992px) {
    .inner-section-container {
      padding: 60px 0; } }
  @media (min-width: 1200px) {
    .inner-section-container {
      padding: 100px 0; } }

/*
	Home Position Sec
----------------------------------------------------------------------------------------------------------------------*/
.position-section {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.4); }
  .position-section .inner-section-container {
    background-image: url("../img/mapa-srbije-1140-light.png");
    background-position: 0 50px;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (min-width: 992px) {
      .position-section .inner-section-container {
        background-position: 0 -30px;
        background-size: cover; } }

.position-item {
  position: relative;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .position-item {
      margin-bottom: 20px; } }
  @media (min-width: 1200px) {
    .position-item {
      margin-bottom: 30px; } }

.position-item__icon {
  color: #f35339;
  position: absolute;
  font-size: 24px;
  line-height: 24px; }
  @media (min-width: 768px) {
    .position-item__icon {
      font-size: 60px;
      line-height: 65px; } }
  @media (min-width: 992px) {
    .position-item__icon {
      font-size: 70px;
      line-height: 65px; } }
  @media (min-width: 1200px) {
    .position-item__icon {
      font-size: 80px;
      line-height: 65px; } }

.position-item__text {
  margin-left: 40px;
  color: #222b34;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800; }
  @media (min-width: 768px) {
    .position-item__text {
      margin-left: 70px;
      font-size: 60px;
      line-height: 65px; } }
  @media (min-width: 992px) {
    .position-item__text {
      margin-left: 70px;
      font-size: 70px;
      line-height: 65px; } }
  @media (min-width: 1200px) {
    .position-item__text {
      margin-left: 80px;
      font-size: 80px;
      line-height: 65px; } }

.position-item-half {
  float: left;
  min-width: 470px; }

/* paral-sec
-------------------------------------------------------*/
.paral-sec {
  padding: 100px 0;
  background-color: #fff;
  text-align: center; }
  @media (min-width: 992px) {
    .paral-sec {
      padding: 200px 0; } }

.paral-sec__title {
  font-size: 32px;
  font-weight: 800;
  color: #fff;
  margin: 0 0 25px; }
  @media (min-width: 992px) {
    .paral-sec__title {
      font-size: 70px; } }

.paral-sec__text {
  color: #fff;
  font-size: 18px;
  margin: 15px 0; }
  @media (min-width: 992px) {
    .paral-sec__text {
      font-size: 24px;
      margin: 30px 0; } }

.paral-sec.bg-light {
  background-color: #f7f7f7; }

/* news-section
-------------------------------------------------------*/
.news-section {
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.4); }
  .news-section .inner-section-container {
    background-image: url("../img/mapa-apatin-1140-light.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 -70px; }

/*   Page with sidebar
----------------------------------------------------------------------------------------------------------------------*/
.page-title-wrap {
  padding: 20px 0; }

.page-wysiwyg-content {
  padding: 20px 0; }

.page-main-img {
  margin-bottom: 30px; }

.wysiwyg-content__kontakt {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  background-color: #f7f7f7; }
  @media (min-width: 992px) {
    .wysiwyg-content__kontakt {
      float: right;
      width: 50%;
      margin-left: 20px;
      margin-bottom: 10px; } }

.sub-static__galery .sub-static__galery-header {
  margin: 20px 0; }

.sub-static__galery .sub-static__galery-title {
  margin: 0; }

.sub-static__galery .work-item {
  margin-bottom: 30px; }

/*   Search page
----------------------------------------------------------------------------------------------------------------------*/
.search-prev {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5; }
  .search-prev:last-child {
    border-bottom: none; }

.search-prev__header {
  position: relative; }
  .search-prev__header a {
    color: #222b34; }
    .search-prev__header a:hover, .search-prev__header a:focus {
      text-decoration: none;
      color: #f35339; }

.search-prev__num {
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid #222b34;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 26px;
  font-size: 20px;
  font-weight: 800; }

.search-prev__title {
  margin-left: 50px; }

/*   page-gallery
----------------------------------------------------------------------------------------------------------------------*/
.page-gallery__body .work-item {
  margin-bottom: 30px; }

/*   box_list
----------------------------------------------------------------------------------------------------------------------*/
.box_list {
  padding: 60px 0; }
  .box_list .work-item {
    margin-bottom: 30px; }

/*   grad_prev
----------------------------------------------------------------------------------------------------------------------*/
.grad_prev {
  min-height: 250px;
  position: relative; }
  .grad_prev .grad_prev-grb {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0;
    top: 0; }
    .grad_prev .grad_prev-grb img {
      max-width: 100%; }
  .grad_prev .grad_prev-body {
    margin-left: 220px;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5; }

/*   404 page
----------------------------------------------------------------------------------------------------------------------*/
.page-404 {
  text-align: center; }

.page-404 .error-404 {
  font-size: 200px;
  font-weight: 800; }

.page-404 a,
.page-404 p {
  font-size: 14px; }

.search-button {
  position: absolute;
  top: 12px;
  right: 15px;
  background-color: transparent;
  border: none; }

.search-button i {
  color: #a1a1a1; }

/*   prezentacija-slider
----------------------------------------------------------------------------------------------------------------------*/
.simple-ajax-popup {
  display: block; }

.prezentacija-slider-wrap {
  max-width: 960px;
  margin: 0 auto; }

.ajax-popup__mfp-close {
  width: 84px;
  height: 84px;
  line-height: 84px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: .85;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 68px;
  font-family: Arial, Baskerville, monospace; }

/*   page-slider-wrap
----------------------------------------------------------------------------------------------------------------------*/
.page-slider-wrap .work-item {
  border: 1px solid #e5e5e5; }

.sidebar-widget {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .sidebar-widget {
      width: 50%;
      float: left; } }
  @media (max-width: 479px) {
    .sidebar-widget {
      width: 100%;
      float: left; } }

.sidebar-widget__nav {
  padding: 10px 0; }
  .sidebar-widget__nav li a {
    color: #222b34;
    display: block;
    padding: 8px 15px;
    font-size: 16px;
    font-size: 1.6rem;
    position: relative;
    transition: background-color .2s ease-in-out;
    font-weight: 700; }
    .sidebar-widget__nav li a:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      background-color: #222b34;
      width: 200px; }
      @media (max-width: 479px) {
        .sidebar-widget__nav li a:after {
          width: 100%; } }
    .sidebar-widget__nav li a:hover, .sidebar-widget__nav li a:focus {
      background-color: #222b34;
      color: #fff; }
      .sidebar-widget__nav li a:hover:after, .sidebar-widget__nav li a:focus:after {
        bottom: 0; }
  .sidebar-widget__nav li:first-child a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    background-color: #222b34;
    width: 200px; }
    @media (max-width: 479px) {
      .sidebar-widget__nav li:first-child a:before {
        width: 100%; } }

.sidebar-widget-news .sidebar-widget__body {
  background-image: url("../img/side-bar-news-BG-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: -15px;
  padding-right: 15px;
  padding-bottom: 20px; }

.sidebar-widget__news-list {
  padding: 10px 0; }
  .sidebar-widget__news-list li a.news-prev {
    color: #222b34;
    display: block;
    min-height: 64px;
    padding: 8px 15px;
    margin-bottom: 0; }
    .sidebar-widget__news-list li a.news-prev:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      background-color: #222b34;
      width: 200px; }
      @media (max-width: 479px) {
        .sidebar-widget__news-list li a.news-prev:after {
          width: 100%; } }
    .sidebar-widget__news-list li a.news-prev .news-prev__date {
      left: 15px;
      top: 8px; }
    .sidebar-widget__news-list li a.news-prev:hover, .sidebar-widget__news-list li a.news-prev:focus {
      background-color: #222b34;
      color: #fff; }
      .sidebar-widget__news-list li a.news-prev:hover:after, .sidebar-widget__news-list li a.news-prev:focus:after {
        bottom: 0; }
  .sidebar-widget__news-list li:first-child .news-prev:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    background-color: #222b34;
    width: 200px; }
    @media (max-width: 479px) {
      .sidebar-widget__news-list li:first-child .news-prev:before {
        width: 100%; } }

.widget-banner {
  margin-bottom: 20px; }

.page-wysiwyg-content img {
  padding: 20px; }

/*  news-prev
----------------------------------------------------------------------------------------------------------------------*/
.home-news-list {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  @media (min-width: 480px) {
    .home-news-list {
      padding: 40px; } }

.news-prev {
  position: relative;
  min-height: 54px;
  margin-bottom: 15px; }

.news-prev__date {
  position: absolute;
  left: 0;
  top: 0;
  border: 4px solid #222b34;
  width: 48px;
  height: 48px;
  text-align: center; }

.news-prev__date-day {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  display: block; }

.news-prev__date-month {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  display: block;
  opacity: .8; }

.news-prev__text {
  margin-left: 60px; }

/*  news-prev-large
----------------------------------------------------------------------------------------------------------------------*/
.news-prev-large {
  position: relative;
  min-height: 170px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5; }
  @media (min-width: 480px) {
    .news-prev-large .news-prev__text {
      margin-left: 160px; } }
  .news-prev-large:last-child {
    border-bottom: none; }

.news-prev-large__header {
  position: relative;
  margin-bottom: 15px; }
  @media (min-width: 480px) {
    .news-prev-large__header {
      margin-left: 160px; } }
  .news-prev-large__header a {
    transition: all .3s ease-in-out;
    display: block;
    color: #222b34; }
    .news-prev-large__header a:hover, .news-prev-large__header a:focus {
      color: #f35339; }

.news-prev__title {
  min-height: 48px;
  margin-left: 60px;
  margin-right: 20px;
  line-height: 1.2;
  font-size: 16px;
  font-size: 1.6rem;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .news-prev__title {
      line-height: 1.2;
      font-size: 18px;
      font-size: 1.8rem; } }

.news-prev__img {
  display: none; }
  .news-prev__img img {
    display: block; }
  @media (min-width: 480px) {
    .news-prev__img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 150px; } }

/*  news-full
----------------------------------------------------------------------------------------------------------------------*/
.news-full__img {
  position: relative; }

.news-full__text .news-prev__date {
  margin-right: 30px;
  margin-bottom: 10px;
  float: left;
  position: static; }

.sidebar-widget-gallery .sidebar-widget__body {
  margin-left: 15px; }

.sidebar__works-grid {
  margin-left: -5px;
  margin-right: -5px; }

.sidebar__work-item {
  float: left;
  width: 50%;
  padding: 5px; }

/* Portfolio
-------------------------------------------------------*/
.work-img {
  position: relative;
  overflow: hidden; }

.work-img img {
  overflow: hidden;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.work-img a.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  width: auto; }

.work-img > a,
.work-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: relative; }

.work-overlay {
  background-color: rgba(20, 20, 20, 0.7);
  border: 10px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.work-description {
  position: absolute;
  display: block;
  left: 8%;
  top: 8%;
  width: 80%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.work-description h3 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #fff; }

.work-description a {
  color: #fff; }

.work-description span {
  font-size: 13px;
  color: #fff;
  display: inline-block; }

.work-description h3,
.work-description span {
  -webkit-transform: translateX(-360px);
  -moz-transform: translateX(-360px);
  -ms-transform: translateX(-360px);
  -o-transform: translateX(-360px);
  transform: translateX(-360px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.work-item:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1); }

.work-item:hover .work-description h3,
.work-item:hover .work-description span {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }

.work-item:hover .work-description span {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.work-item:hover .work-overlay {
  opacity: 1;
  z-index: 1; }

/* Latest Works
-------------------------------------------------------*/
.work-item .work-overlay:hover,
.work-item:hover .portfolio-overlay {
  opacity: 1;
  z-index: 1; }

.portfolio-overlay {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-icons {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  margin-top: -20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-icons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 14px;
  margin: 0 3px;
  line-height: 40px;
  text-align: center;
  color: #222b34;
  background-color: #fff;
  border-radius: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-icons a:hover {
  color: #fff;
  background-color: #f35339; }

.work-item:hover .portfolio-overlay .project-icons {
  position: absolute;
  top: 50%; }

.works-grid.titles .work-description h3,
.works-grid.titles .work-description span {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none; }

.works-grid.titles .work-description {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  height: 44px;
  overflow: hidden; }

.works-grid.titles .work-description a {
  color: #111; }

.works-grid.titles .work-description a:hover {
  color: #f35339; }

.album-list-page .work-item {
  margin-bottom: 20px; }

.album-single-page .work-item {
  margin-bottom: 30px; }

/* Contact
-------------------------------------------------------*/
.contact-row {
  margin-left: -8px;
  margin-right: -8px; }

.contact-name,
.contact-email {
  padding: 0 8px; }

.contact-item {
  position: relative;
  padding-left: 70px;
  padding-top: 5px;
  margin-top: 30px; }

.contact-item:first-of-type {
  margin-top: 38px; }

.contact-item h6 {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 7px; }

.contact-name-title {
  margin-bottom: 5px; }

.contact-work-hour {
  font-size: 18px; }

#contact p {
  margin: 0; }

.contact-item p,
.contact-item span,
.contact-item a {
  font-size: 16px; }

.contact-item a:hover {
  color: #111; }

.contact-icon {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #222b34;
  text-align: center;
  position: absolute;
  left: 0; }

.contact-icon i {
  font-size: 18px;
  color: #222b34;
  line-height: 47px; }

.gmap {
  position: relative;
  width: 100%;
  height: 450px; }

#contact-form .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff; }

#contact-form .message.error {
  background-color: #f44336; }

#contact-form .message.success {
  background-color: #4CAF50; }

/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"],
select,
textarea {
  position: relative;
  height: 47px;
  line-height: 47px;
  border: 2px solid #e3e3e3;
  background-color: transparent;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 16px;
  color: #7a7a7a;
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  -ms-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out; }

textarea#comment {
  height: 190px;
  padding: 0 16px;
  margin-bottom: 10px; }

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
  border-color: #f35339;
  outline: none;
  box-shadow: none; }

textarea {
  height: auto;
  padding: 0px 16px; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7a7a7a; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #7a7a7a;
  opacity: 1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #7a7a7a;
  opacity: 1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7a7a7a; }

select {
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer; }

select::-ms-expand {
  display: none; }

.select {
  position: relative;
  cursor: pointer; }

.select i {
  position: absolute;
  top: 14px;
  right: 18px;
  pointer-events: none;
  font-size: 18px; }

.form-desc {
  color: rgba(34, 43, 52, 0.5);
  margin-bottom: 15px; }

.og_ko-item {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5; }
  .og_ko-item:last-child {
    border-bottom: none; }
  .og_ko-item.finished {
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    opacity: .8;
    padding: 10px; }

.og_ko-item-header {
  position: relative;
  min-height: 48px;
  margin-bottom: 10px; }

.og_ko-title {
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: none;
  letter-spacing: normal; }

.og_ko-date {
  display: inline-block;
  border: 4px solid #222b34;
  text-align: center; }
  .og_ko-date .og_ko-date-item {
    float: left;
    font-weight: 700;
    padding: 3px 5px; }
    .og_ko-date .og_ko-date-item.og_ko-date-text {
      border-left: 4px solid #222b34;
      border-right: 4px solid #222b34;
      background-color: #222b34;
      color: #fff; }

.og_ko-text {
  margin-bottom: 15px; }

.doc_download-title {
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1; }

.doc_download-list {
  margin: 0; }

.og_ko-search {
  padding: 20px 20px 4px 20px;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
  background-color: #f7f7f7; }

.og_ko-search #og_ko_name, .og_ko-search #daterange {
  background-color: #fff; }

.og_ko-form-btn-wrap {
  padding-top: 25px; }

.btn-apa-2color {
  color: #fff;
  font-weight: 700;
  display: block;
  line-height: 0;
  font-size: 0; }
  .btn-apa-2color .btn-apa__1 {
    background-color: #222b34;
    padding: 5px 10px;
    line-height: 36px;
    font-size: 18px;
    transition: background-color .2s ease-in-out; }
  .btn-apa-2color .btn-apa__2 {
    background-color: #f35339;
    padding: 5px 10px;
    line-height: 36px;
    font-size: 18px; }
  .btn-apa-2color:hover, .btn-apa-2color:focus {
    color: #fff; }
    .btn-apa-2color:hover .btn-apa__1, .btn-apa-2color:focus .btn-apa__1 {
      background-color: #f35339; }

.btn.btn-color {
  background-color: #f35339;
  color: #fff; }
  .btn.btn-color:hover, .btn.btn-color:focus {
    background-color: #222b34; }

.title-2 {
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: 800; }

.footer-col-title {
  margin: 0 0 20px 0;
  color: #fff; }

.page-title {
  margin: 0;
  font-weight: 800;
  font-size: 18px;
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .page-title {
      font-size: 24px;
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .page-title {
      font-size: 32px;
      font-size: 3.2rem; } }

.sidebar-widget__title {
  padding-left: 15px;
  margin: 0;
  font-weight: 800;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2; }
  .sidebar-widget__title a {
    color: #222b34; }
    .sidebar-widget__title a:hover, .sidebar-widget__title a:focus {
      text-decoration: none;
      color: #f35339; }
  @media (min-width: 768px) {
    .sidebar-widget__title {
      font-size: 24px;
      font-size: 2.4rem; } }

.search-prev__title {
  margin-bottom: 10px; }
