.page-wysiwyg-content {
	@include breakpoint($min992){

	}
	img {
		padding: 20px;
	}
	h3{

	}
}